import { ActivityApi } from '../activity';
import { AddressApi } from '../address';
import { ApplicationsApi } from '../applications';
import { FilesApi } from '../files';
import { ImportExportApi } from '../import-export';
import { JourneysApi } from '../journeys';
import { MilestonesApi } from '../milestones';
import { PhoneApi } from '../phone';
import { SchoolsApi } from '../schools';
import { UsersApi } from '../users';
import { WorkflowsApi } from '../workflows';

// Event
export interface UserProfileEventStatus {
  attended: boolean;
  canceled: boolean;
  registered: boolean;
  waitlisted: boolean;
  no_show: boolean;
  slot_expired: boolean;
  payment_pending: boolean;
  created_at: string;
  updated_at: string;
}

export interface UserProfileEvent {
  event_guid: string;
  event_elements_guid: string;
  event_date: string;
  event_type: string;
  signup_guid: string;
  created_at: string;
  status: UserProfileEventStatus;
  guests_number: number;
}

export interface UserProfileApplicationExport {
  created_at?: string;
  created_by: string;
  file: string;
  file_url: string;
}

export interface UserProfileApplication {
  major: string;
  term: string;
  degree: string;
  student_type: string;
  school: string;
  campus: string;

  index_weight: number;

  // if decision
  board?: { status_guid: string };

  registration_id: string;
  status: string;
  guid: string;
  // use for score if not decision
  numeric_progress: number;

  last_login?: string;
  registered_at?: string;
  completed_at?: string;
  status_changed_at?: string;
  submitted_time?: string;
  updated_at?: string;

  last_export?: UserProfileApplicationExport;
}

export interface UserProfileKey {
  application_guid?: string;
  category?: string;
  fast_application?: boolean;
  key: string;
  key_type?: string;
  namespace: string;
  registration_id?: string;
}

export interface UserProfileNote {
  _id: string;
  admin_id: string;
  body: string;
  taxonomy: string;
  type: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  index_weight: number;
  is_private?: boolean;
  allowed_viewers?: string[];
}

// Workflow
export enum UserProfileWorkflowRegistrationType {
  Trigger = 'trigger',
  Manual = 'manual',
  Scheduled = 'scheduled',
  User = 'user'
}

export interface UserProfileWorkflowRegisteredBy {
  type: UserProfileJourneyRegistrationType;
  guid?: string;
  user_id?: string;
}

export interface UserProfileWorkflowStatus {
  state: WorkflowsApi.WorkflowState;
  starts_at: string;
  finished_at: string;
  aborted_at: string;
  registered_at: string;
  last_executed_step: string;
  last_executed_step_time: string;
  registered_by: UserProfileWorkflowRegisteredBy;
}

export interface UserProfileWorkflowStep {
  index: string;
  branch_taken: boolean;
  actions: string[];
  executed_at: string;
  description: string;
  status: {
    last_executed_step: string;
  };
}

export interface UserProfileWorkflow {
  workflow: WorkflowsApi.Workflow;
  type: string;
  key: string;
  guid: string;
  status: UserProfileWorkflowStatus;
  steps: UserProfileWorkflowStep[];
  finished_at: string;
  aborted_at: string;
  starts_at: string;
}

// Student Activity
export interface UserProfileStudentActivity {
  name: string;
  position: string;
  index_weight: number;
  item_id?: string;
  years_held: number;
  hours_week: number;
}

// Relationship
export enum UserProfileRelationshipType {
  System = 'system',
  Custom = 'custom'
}

export interface UserProfileRelationship {
  id: string;
  type: UserProfileRelationshipType;
  relation: string;
  target: string;
  is_targeted: boolean;
  meta?: string;
  _subdom: string;
  updated_at: string;
  updated_by: string;
  created_at: string;
  created_by: string;
}

// Athletics
export interface UserProfileAthletic {
  index_weight: number;
  sport: string;
  integration_id: string;
  term: string;
  position: string;
  rating: number;
  recruit: false;
  recruit_activity_date: string;
  recruit_status: string;
  source: string;
  source_date: string;
  notes: string;
  url: string;
  item_id?: string;
}

// Source
export interface UserProfileSource {
  type: UsersApi.UserSourceType;
  name?: string;
  alias?: string;
  segment?: string;
  event_date?: string;
  timestamp: string;
  properties: UsersApi.UserSourceProperties;
  index_weight: number;
  url?: string;
  item_id?: string;
}
export interface UserProfileDocument {
  app_guid: string;
  file: string;
  form_guid: string;
  name: string;
  slug: string;
  type: string;
}

export interface UserProfileIdentity {
  type: UsersApi.UserIdentityTypes;
  value: string;
  index_weight: number;
  item_id?: string;
}

export interface UserProfileIdentityOption {
  type: UsersApi.UserIdentityTypes;
  name: string;
}

export interface UserProfileSchool {
  type: SchoolsApi.SchoolType;
  entry_type?: SchoolsApi.HighSchoolType;

  name: string;
  ceeb?: string;
  external_id?: string;

  index_weight?: number;
  item_id?: string;

  city?: string;
  country?: string;
  county?: string;
  state?: string;
  zipcode?: string;
  street_1?: string;

  graduate?: boolean;
  graduate_date?: string;

  start_date?: string;
  end_date?: string;

  gpa?: string;
  gpa_id?: string; // added by BE automatically
  gpa_official?: boolean;
  gpa_rank?: string;
  gpa_scale?: string;

  degree_earned?: string; // tu.degree.2
  associated_degree?: string; // tu.degree.1
  major_minor?: string; // tu.major.1

  ged_date?: string;

  transcript_guid?: string; // file guid

  counselor?: {
    firstname?: string;
    lastname?: string;
    email?: string;
  };
}

export type UserProfileUpdateSchoolDto = Omit<
  UserProfileSchool,
  'transcript_guid'
> & {
  transcript_guid: File | FilesApi.ApiFile | string | null;
};

export type UserProfileCreateSchoolDto = UserProfileUpdateSchoolDto;

export interface UserProfileEmployment {
  companyname: string;
  business?: string;
  position?: string;
  status?: string;
  reimbursement?: boolean;
  start_date?: string;
  end_date?: string;
  index_weight: number;
  item_id?: string;
}

export interface UserProfileEvaluationScore {
  key: string; // "composite",
  value: number; // 30.0
}

export interface UserProfileEvaluation {
  type: string; // "ACT",
  name: string; // "ACT",
  date: string; // ISODate("2021-10-01T00:00:00.000+0000"),
  official: boolean; // true,
  index_weight: number; // NumberInt(2),
  item_id?: string;
  scores: UserProfileEvaluationScore[];
}

export interface EmergencyContact {
  first_name: string;
  last_name: string;
  email: string;
  is_primary: boolean;
  relationship?: string | null;
  cell_phone?: PhoneApi.Phone | null;
  home_phone?: PhoneApi.Phone | null;
  address?: AddressApi.Address | null;
  item_id?: string;
  index_weight?: number;
}

export type EmergencyContactDto = Omit<
  EmergencyContact,
  'index_weight' | 'item_id'
>;

export interface EmergencyContactUpdateResponse {
  item_id: string;
  index_weight: number;
  status: string;
}

export enum CalculatedFieldType {
  ActiveTerm = 'active_term',
  ActiveCampus = 'active_campus',
  ActiveMajor = 'active_major',
  ActiveDegree = 'active_degree',
  ActiveStudentType = 'active_student_type',
  DateOfLastEmailDelivered = 'date_of_last_email_delivered',
  PreferredOpenTime = 'preferred_open_time',
  EngagementScore = 'engagement_score',
  FunnelStage = 'funnel_stage',
  CurrentFunnelStage = 'current_funnel_stage',
  TotalSources = 'total_sources',
  TotalUserLoginEvents = 'total_user_login_events',
  TotalEmailOpenedEvents = 'total_email_opened_events',
  TotalEmailDeliveredEvents = 'total_email_delivered_events',
  TotalEmailsOpened = 'total_emails_opened',
  TotalEmailClickedEvents = 'total_email_clicked_events',
  TotalEmailsClicked = 'total_emails_clicked',
  TotalPageviewEvents = 'total_pageview_events',
  UniqueEmailOpenRate = 'unique_email_open_rate',
  TotalApplications = 'total_applications',
  TotalTasks = 'total_tasks',
  TotalOpenTasks = 'total_open_tasks',
  TotalCompletedTasks = 'total_completed_tasks',
  TotalEventsAttended = 'total_events_attended',
  EmailSuppression = 'email_suppression',
  LastUserActivity = 'last_user_activity',
  LastTouchPoint = 'last_touch_point',
  DateOfLastDecisionReleased = 'date_of_last_decision_released',
  DateOfLastEmailClicked = 'date_of_last_email_clicked',
  DateOfLastEmailOpened = 'date_of_last_email_opened',
  DateOfLastEventAttended = 'date_of_last_event_attended',
  DateOfLastEventRegistration = 'date_of_last_event_registration',
  DateOfLastExternalActivity = 'date_of_last_external_activity',
  DateOfLastFormSaved = 'date_of_last_form_saved',
  DateOfLastFormSubmitted = 'date_of_last_form_submitted',
  DateOfLastPageview = 'date_of_last_pageview',
  DateOfLastProfileAddressUpdated = 'date_of_last_profile_address_updated',
  DateOfLastProfileEmailUpdated = 'date_of_last_profile_email_updated',
  DateOfLastProfileMilestoneUpdated = 'date_of_last_profile_milestones_updated',
  DateOfLastProfileNameUpdated = 'date_of_last_profile_name_updated',
  DateOfLastProfileUpdated = 'date_of_last_profile_updated',
  DateOfLastSmsClicked = 'date_of_last_sms_clicked',
  DateOfLastSmsDelivered = 'date_of_last_sms_delivered',
  DateOfLastUserMerged = 'date_of_last_user_merged',
  FirstAppGuid = 'first_app_guid',
  FirstPageUrl = 'first_pageview_url',
  FirstSource = 'first_source',
  FormsSubmitted = 'forms_submitted',
  LastFormSubmittedGuid = 'last_form_submitted_guid',
  LastPageviewUrl = 'last_pageview_url',
  LastSeenCity = 'last_seen_city',
  LastSeenCountry = 'last_seen_country',
  LastSeenRegionName = 'last_seen_region_name',
  LastSeenTimezone = 'last_seen_timezone',
  LastSubmittedAppGuid = 'last_submitted_app_guid',
  TotalFormsSubmitted = 'total_forms_submitted',
  TotalSmsClicked = 'total_sms_clicked',
  TotalSmsClickedEvents = 'total_sms_clicked_events',
  TotalSmsDeliveredEvents = 'total_sms_delivered_events',
  TypeOfLastExternalActivity = 'type_of_last_external_activity',
  FurthestFunnelStage = 'furthest_funnel_stage',
  FirstSourceType = 'first_source_type',
  FirstSourceDescription = 'first_source_description'
}

export type CalculatedFieldValue = string | number | boolean | string[];

export type CalculatedFields = Record<
  CalculatedFieldType | 'updated_at',
  CalculatedFieldValue
>;

export interface UserProfileFamilyMember {
  type: string; // "guardian",
  relationship: string; // "father",
  first_name: string; // "Ken",
  middle_name?: string;
  last_name: string; // "McGraw",
  suffix?: string;
  title?: string;
  email?: string; // "test@test.",
  gender?: string;

  degree?: string;

  date_of_birth?: string;
  country_of_birth?: string;
  city_of_birth?: string;
  country_of_citizenship?: string;

  living?: boolean;

  graduate_of_school?: boolean;
  college?: string;
  occupation?: string;
  employer?: string;
  job_title?: string;
  marital_status?: string;

  same_address?: boolean;
  address?: {
    type?: 'home';
    city?: string; // "Westbury"
    country?: string; // "USA"
    county?: string; // "Nassau County"
    state?: string; // "NY"
    street_1?: string; // "865 Merrick Avenue"
    zipcode?: string; // "11590"
  };

  phone?: UserProfileFamilyMemberPhone;

  index_weight: number; // NumberInt(1)
  item_id?: string;
}

export interface UserProfileFamilyMemberPhone {
  type?: 'home' | 'cell';
  country_alpha_2?: string; // "CA"
  country_code?: string; // "1"
  number?: string; // "1231312"
  updates?: boolean; // true
}

// Journeys
export interface UserProfileJourneyStep {
  id: string;
  step_id: string;
  activity_id: string;
  activity_time: string;
  activity_type: ActivityApi.ActivityActionType;
  evaluated_at: string;
  index?: string;
}

export enum UserProfileJourneyRegistrationType {
  Trigger = 'trigger',
  Manual = 'manual',
  Action = 'action'
}

export interface UserProfileJourneyRegisteredBy {
  type: UserProfileJourneyRegistrationType;
  guid: string;
  repeat_context: JourneysApi.TriggerParams;
  user_id?: string;
}

export interface UserProfileJourneyExitedBy {
  trigger: string;
  activity_id: string;
  activity_type: ActivityApi.ActivityActionType;
}

export interface UserProfileJourneyStatus {
  active?: boolean;
  state: JourneysApi.State;
  repeat_trigger?: boolean;
  last_step_completed?: string;

  registered_at: string;
  registered_by: UserProfileJourneyRegisteredBy;

  deactivated_at?: string | null;
  completed_at?: string | null;

  exited_at?: string;
  exited_by?: UserProfileJourneyExitedBy;

  updated_at: string;
}

interface UserProfileJourneyStepAction {
  step_group_id: string;
  step_id: string;
  id: string;
  success: boolean;
  time: string;
}

export interface UserProfileJourney {
  guid: string;
  status: UserProfileJourneyStatus;
  context: JourneysApi.TriggerParams;
  key: string;
  steps: UserProfileJourneyStep[];
  step_actions?: UserProfileJourneyStepAction[];
  updated_at: string;
  step_hash?: string;
}

export interface UserProfileOrganization {
  relation: string | null;
  target: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export interface UserProfileHolds {
  amount: number;
  description: string;
  index_weight: number;
  item_id: string;
  end_date: string;
  start_date: string;
  updated_at: string;
  placed_by: string;
  status: string;
  subtype: string;
  type: string;
}

export interface UserProfileSegment {
  guid: string;
  time: string;
}

export interface LockerKey {
  key: string;
  key_type?: 'random:40' | 'random:10' | string;
  namespace: string;
  application_guid?: string;
  category?: 'user_auth' | string;
  custom_key?: string;
  expiration_date?: string;
  fast_application?: boolean;
  registration_id?: string;
  usage?: 'google_login' | string;
}

export interface UserProfile {
  _id: string;
  // TODO(petar): temporarily
  id?: string;
  type: UsersApi.UserType;
  active: boolean;
  owner?: string;
  avatar?: FilesApi.ApiFile;
  email: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  preferred_name?: string;
  social_security_number?: string;
  phone?: PhoneApi.Phone[];
  identities?: UserProfileIdentity[];
  profile_type: UsersApi.ProfileType;
  labels?: string[];
  events?: UserProfileEvent[];
  milestones?: MilestonesApi.Milestone[];
  applications?: UserProfileApplication[];
  keys?: UserProfileKey[];
  notes?: UserProfileNote[];
  workflows?: UserProfileWorkflow[];
  activities?: UserProfileStudentActivity[];
  user_relationships?: UserProfileRelationship[];
  athletics?: UserProfileAthletic[];
  territory?: string;
  visibility_groups?: string[];
  addresses?: AddressApi.Address[];
  sources?: UserProfileSource[];
  documents: UserProfileDocument[];
  evaluations?: UserProfileEvaluation[];
  education?: {
    schools?: UserProfileSchool[];
  };
  employment?: {
    employees: UserProfileEmployment[];
  };
  family?: {
    members: UserProfileFamilyMember[];
  };
  calculated?: CalculatedFields;
  holds?: UserProfileHolds[];
  custom?: Record<string, any>;
  journeys: UserProfileJourney[];
  organizations?: UserProfileOrganization[];
  segments?: UserProfileSegment[];
  info_requests?: ApplicationsApi.Recommendation[];
  updated_at: string;
  created_at: string;
  territory_manual_updates?: boolean;
  emergency_contacts?: EmergencyContact[];
  title?: string;
}

export interface ProfileUpdateDTO {
  template: {
    columns: ImportExportApi.TaskColumn[];
  };
  data: {
    [key: string]: any;
  };
}

export interface ProfileUpdateResponse {
  'user-elementid': string;
  [key: string]: any;
}

const TRAIT_LABELS: Record<CalculatedFieldType, string> = {
  [CalculatedFieldType.ActiveTerm]: 'Active Term',
  [CalculatedFieldType.ActiveCampus]: 'Active Campus',
  [CalculatedFieldType.ActiveMajor]: 'Active Major',
  [CalculatedFieldType.ActiveDegree]: 'Active Degree',
  [CalculatedFieldType.ActiveStudentType]: 'Active Student Type',
  [CalculatedFieldType.DateOfLastEmailDelivered]: 'Date of Last Email Received',
  [CalculatedFieldType.PreferredOpenTime]: 'Preferred Open Time',
  [CalculatedFieldType.EngagementScore]: 'Engagement Score',
  [CalculatedFieldType.FunnelStage]: 'Active Funnel Stage',
  [CalculatedFieldType.CurrentFunnelStage]: 'Current Funnel Stage',
  [CalculatedFieldType.TotalSources]: 'Total Sources',
  [CalculatedFieldType.TotalUserLoginEvents]: 'Total User Logins',
  [CalculatedFieldType.TotalEmailOpenedEvents]: 'Total Email Opens',
  [CalculatedFieldType.TotalEmailDeliveredEvents]: 'Total Emails Delivered',
  [CalculatedFieldType.TotalEmailsOpened]: 'Total Unique Emails Opened',
  [CalculatedFieldType.TotalEmailClickedEvents]: 'Total Email Clicks',
  [CalculatedFieldType.TotalEmailsClicked]: 'Total Unique Emails Clicked',
  [CalculatedFieldType.TotalPageviewEvents]: 'Total Pageviews',
  [CalculatedFieldType.UniqueEmailOpenRate]: 'Unique Email Open Rate',
  [CalculatedFieldType.TotalApplications]: 'Total Applications',
  [CalculatedFieldType.TotalTasks]: 'Total Tasks',
  [CalculatedFieldType.TotalOpenTasks]: 'Total Open Tasks',
  [CalculatedFieldType.TotalCompletedTasks]: 'Total Completed Tasks',
  [CalculatedFieldType.TotalEventsAttended]: 'Total Events Attended',
  [CalculatedFieldType.EmailSuppression]: 'Email Suppression',
  [CalculatedFieldType.LastUserActivity]: 'Last User Activity',
  [CalculatedFieldType.LastTouchPoint]: 'Last Touch Point',
  [CalculatedFieldType.DateOfLastDecisionReleased]:
    'Date of Last Decision Released',
  [CalculatedFieldType.DateOfLastEmailClicked]: 'Date of Last Email Click',
  [CalculatedFieldType.DateOfLastEmailOpened]: 'Date of Last Email Open',
  [CalculatedFieldType.DateOfLastEventAttended]: 'Date of Last Event Attended',
  [CalculatedFieldType.DateOfLastEventRegistration]:
    'Date of Last Event Registration',
  [CalculatedFieldType.DateOfLastExternalActivity]:
    'Date of Last Custom Activity',
  [CalculatedFieldType.DateOfLastFormSaved]: 'Date of Last Form Saved',
  [CalculatedFieldType.DateOfLastFormSubmitted]: 'Date of Last Form Submitted',
  [CalculatedFieldType.DateOfLastPageview]: 'Date of Last Pageview',
  [CalculatedFieldType.DateOfLastProfileAddressUpdated]:
    'Date of Last Address Update From Profile',
  [CalculatedFieldType.DateOfLastProfileEmailUpdated]:
    'Date of Last Email Update From Profile',
  [CalculatedFieldType.DateOfLastProfileMilestoneUpdated]:
    'Date of Last Milestone Update From Profile',
  [CalculatedFieldType.DateOfLastProfileNameUpdated]:
    'Date of Last Name Update From Profile',
  [CalculatedFieldType.DateOfLastProfileUpdated]: 'Date of Last Profile Update',
  [CalculatedFieldType.DateOfLastSmsClicked]: 'Date of Last SMS Click',
  [CalculatedFieldType.DateOfLastSmsDelivered]: 'Date of Last SMS Received',
  [CalculatedFieldType.DateOfLastUserMerged]: 'Date of Last User Merged',
  [CalculatedFieldType.FirstAppGuid]: 'First Started App Type',
  [CalculatedFieldType.FirstPageUrl]: 'First Pageview URL',
  [CalculatedFieldType.FirstSource]: 'First Source',
  [CalculatedFieldType.FormsSubmitted]: 'All Forms Submitted',
  [CalculatedFieldType.LastFormSubmittedGuid]: 'Last Form Submitted',
  [CalculatedFieldType.LastPageviewUrl]: 'Last Pageview URL',
  [CalculatedFieldType.LastSeenCity]: 'Last Seen City',
  [CalculatedFieldType.LastSeenCountry]: 'Last Seen Country',
  [CalculatedFieldType.LastSeenRegionName]: 'Last Seen Region Name',
  [CalculatedFieldType.LastSeenTimezone]: 'Last Seen Timezone',
  [CalculatedFieldType.LastSubmittedAppGuid]: 'Last Submitted App Type',
  [CalculatedFieldType.TotalFormsSubmitted]: 'Total Forms Submitted',
  [CalculatedFieldType.TotalSmsClicked]: 'Total Unique SMS Clicks',
  [CalculatedFieldType.TotalSmsDeliveredEvents]: 'Total SMS Delivered',
  [CalculatedFieldType.TypeOfLastExternalActivity]:
    'Type of Last Custom Activity',
  [CalculatedFieldType.TotalSmsClickedEvents]: 'Total SMS Clicks',
  [CalculatedFieldType.FurthestFunnelStage]: 'Furthest Funnel Stage',
  [CalculatedFieldType.FirstSourceType]: 'First Source Type',
  [CalculatedFieldType.FirstSourceDescription]: 'First Source Description'
};

export function getTraitLabelByKey(key: CalculatedFieldType) {
  if (!key) return `N/A`;
  return TRAIT_LABELS[key];
}

const BlacklistedTraits = new Set([CalculatedFieldType.CurrentFunnelStage]);

export const TRAITS_LIST = Object.values(CalculatedFieldType).filter(
  trait => !BlacklistedTraits.has(trait)
);

export const getPhoneNumbersFromUserProfile = (profile: UserProfile) => {
  const phoneNumbers: PhoneApi.Phone[] = [];
  if (profile.phone) {
    profile.phone.forEach(phone => {
      if (phone.valid) {
        phoneNumbers.push({
          number: PhoneApi.getPhoneNumber(phone),
          carrier_name: phone.carrier_name,
          type: phone.type
        });
      }
    });
  }
  return phoneNumbers;
};
